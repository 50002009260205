.avatar {
  width: 144px;
  height: 144px;
  border-radius: 50%;
  margin-bottom: 12px;
  text-align: center;
  img {
    width: 100%;
    height: 100%;
  }
}
.button_view {
  width: 144px;
  text-align: center;
}
