.person_sub{
  margin-left: 24px;
  flex:1;
}

.person_sub_top{
    margin-bottom: 12px;
    color: rgba(0,0,0,.85);
    font-weight: 500;
    font-size: 20px;
    line-height: 28px
}

.person_sub_btm{
  color: rgba(0,0,0,.45)
}