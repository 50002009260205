.passwordStrongBox {
  margin-top: 4px;
  .weak {
    :global .ant-progress-bg {
      background-color: #f50 !important;
    }
  }

  .middle {
    :global .ant-progress-bg {
      background-color: #e4ce2b !important;
    }
  }

  .strong {
    :global .ant-progress-bg {
      background-color: #87d068 !important;
    }
  }

  .passWord {
    display: inline-block;
    margin: 3px 8px 0 6px;
    color: rgba(140, 140, 140, 100);
    font-size: 12px;
  }
  .passStrong {
    display: inline-block;
    margin: 3px 0 0 8px;
    color: rgba(89, 89, 89, 100);
    font-size: 11px;
  }
}